@import './../../../styles/_variable.scss';
.dm-list {
  &__card {
    padding-top: 15px;
    padding-left: 20px;
    margin-bottom: 10px;

    &.card {
      // box-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.5);
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
    }
    &__status {
      padding: 15px;
      background: green;
      color: white;
      margin-right: 20px;
      border-radius: 100%;
    }

    &.new-btn {
      background: $lightBlue;
      color: white;
      font-size: 18px;
      height: 50px;
      padding: 0px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
