@import "./mixins/button";
@import "./variable";

.btn + .btn {
  margin-left: 4px;
}

.btn {
  &:hover {
    transform: translateY(-1px);
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
  // Icons

  i:not(:first-child),
  svg:not(:first-child) {
    margin-left: 0.5rem;
  }

  i:not(:last-child),
  svg:not(:last-child) {
    margin-right: 0.5rem;
  }
}

//button
.btn-primary {
  @include button-variant(#fff, $btn-primary, $btn-primary-box-shadow);
}
.btn-success {
  @include button-variant(#fff, $btn-success, $btn-success-box-shadow);
}
.btn-info {
  @include button-variant(#fff, $btn-info, $btn-info-box-shadow);
}
.btn-warning {
  @include button-variant(#fff, $btn-warning, $btn-warning-box-shadow);
}
.btn-danger {
  @include button-variant(#fff, $btn-warning, $btn-danger-box-shadow);
}
.btn-white {
  @include button-variant(
    $btn-white-text-color,
    $btn-white,
    $btn-white-box-shadow
  );
}

//outline nutton
.btn-outline-success {
  @include button-outline-variant(#fff, $btn-success, $btn-success-box-shadow);
}
.btn-outline-info {
  @include button-outline-variant(#fff, $btn-info, $btn-info-box-shadow);
}
.btn-outline-warning {
  @include button-outline-variant(#fff, $btn-warning, $btn-warning-box-shadow);
}
.btn-outline-danger {
  @include button-outline-variant(#fff, $btn-info, $btn-danger-box-shadow);
}
