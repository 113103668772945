.breadcrumb {
  display: flex;
  padding: 13px 6px !important;
  background-color: hsla(230, 100%, 89%, 0.588);
  border-radius: 4px;
  &_home {
    margin-left: 10px;
  }
  &_item {
    letter-spacing: 1px;
    &::before {
      content: "-";
      margin: 0px 10px;
    }
  }
}
