.link-buttons {
    a {
        text-decoration: none;
        color: black;
    }
    padding-top: 30px;
    &__card {
        padding:30px;
        margin-bottom: 15px;

        &.card {
            box-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.4);
            border-radius: 20px;
        }
    }
}