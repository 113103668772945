@import "./../styles/variable";
.main-content {
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 20px;
}

._loading_overlay_overlay {
  background: rgba($color: #fff, $alpha: 0.4);
}
