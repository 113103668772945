@import './../../styles/variable.scss';
.custom-nav {
  display: flex;
  height: 50px;
  align-items: center;
  background: $primary;
  padding: 40px 10% 40px 10%;
  justify-content: space-between;
  box-shadow: 2px 2px 2px black;

  &__brand {
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
  }

  &__icon {
    color: white;
    font-size: 45px;
  }
}

@media only screen and (max-width: 600px) {
  .custom-nav {
    &__brand {
      color: white;
      font-size: 14px;
      font-weight: 500;
      line-height: 5px;
      text-align: center;
    }
  }
}
