$primary: #5e72e4;
$success: #2dce89;
$info: #11cdef;
$warning: #fb6340;
$danger: #f5365c;
$white: #fff;

//BUTTON
$btn-primary: $primary;
$btn-primary-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);

$btn-success: $success;
$btn-success-broder: $success;
$btn-success-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);

$btn-info: $info;
$btn-info-border: $info;
$btn-info-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);

$btn-warning: $warning;
$btn-warning-border: $warning;
$btn-warning-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(251, 99, 64, 0.5);

$btn-danger: $danger;
$btn-danger-border: $danger;
$btn-danger-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);

$btn-white: $white;
$btn-white-border: $white;
$btn-white-text-color: #212529;
$btn-white-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);
