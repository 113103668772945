@mixin button-variant($text-color, $color, $box-shadow) {
  color: $text-color;
  background-color: $color;
  border-color: $color;
  box-shadow: $box-shadow;

  &:hover {
    color: $text-color;
    background-color: $color;
    border-color: $color;
  }

  &:focus,
  &.focus {
    box-shadow: $box-shadow;
  }
  &.disabled,
  &:disabled {
    color: $text-color;
    background-color: $color;
    border-color: $color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $text-color;
    background-color: $color;
    border-color: $color;
  }
}

@mixin button-outline-variant($hover-color, $color, $box-shadow) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $hover-color;
    background-color: $color;
    border-color: $color;
  }

  &:focus,
  &.focus {
    box-shadow: $box-shadow;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $hover-color;
    background-color: $color;
    border-color: $color;

    &:focus {
      $box-shadow: $box-shadow;
    }
  }
}
