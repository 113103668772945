@import "./../../styles/variable";
.sidebar {
  width: 200px;
  color: #fff;
  background: $primary;
  height: calc(100vh);
  position: fixed;
  top: 80px;
  padding-top: 20px;
  transition: 0.5s;
  z-index: 1;
}

.sidebar.hidden {
  margin-left: 0;
  transition: 0.5s;
  transform: translateX(-100%);
}

.nav-item {
  padding-left: 5%;
}

.nav-link {
  color: white !important;
  font-size: 18px;
  font-weight: bold;

  .icon {
    font-size: 24px !important;
    margin-right: 10px;
  }
}

.nav-item:hover {
  background: white;
}

.nav-item:hover .nav-link {
  color: black !important;
}
