@import './../../../styles/variable.scss';
.login-container {
  width: 100%;
  height: 100vh;

  @at-root #{&}__card {
    width: 50%;
    margin-left: 25%;
    margin-top: 10%;
    padding: 0px;
    border-radius: 10px !important;

    .card-title {
      background: $primary !important;
      padding: 10px 0px 10px 20px;
      font-size: 25px;
      text-align: center;
      color: white;
    }

    @at-root #{&}__body {
      width: 70%;
      margin-left: 10%;

      .form-group {
        margin-bottom: 40px;

        label {
          margin-bottom: 10px;
        }
      }

      .btn {
        width: 30%;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .login-container {
    @at-root #{&}__card {
      width: 70%;
    }
  }
}
