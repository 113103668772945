@import "./styles/variable.scss";
body {
  padding: 0px !important;
  margin: 0px !important;
  background: #f7f8fd !important;
}
.form-group-item {
  margin-bottom: 10px;

  label {
    margin-bottom: 4px;
  }
}

a {
  text-decoration: none !important;
}

.submit-btn {
  width: 100%;
}
