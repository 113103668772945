.not-found-container {
  height: 100vh;
  width: 100%;
  background: #afceff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
      width:340px;
      height: 340px;
  }

  p {
      font-size: 25px;
  }

}
