.member-list-card {
  padding: 5px;
  &.card {
    background: rgba($color: #041185, $alpha: 0.6);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  margin-bottom: 4px;

  svg {
    cursor: pointer;
  }
}
