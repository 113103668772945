@import "./../../styles/variable.scss";
.item-list-item {
  &.card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 3%;
    padding-right: 3%;
  }

  &__info {
    &__no {
      font-size: 16px;
      margin-right: 10px;
      border-right: 1px solid black;
      padding-right: 10px;
    }

    &__title {
      font-size: 16px;
      color: $primary;
      margin-right: 5px;
    }
  }

  &__actions {
    span {
      padding: 10px 15px;
      margin-right: 10px;
      border-radius: 100%;
      color: white;
    }
  }
}
