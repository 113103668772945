.datepicker-container {
    .DayPickerInput {
        width: 100%;
        input {
            width: 100%;
            padding: .375rem .75rem;
            border: 1px solid #ced4da;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
    }
}