@import "./../../../styles/_variable.scss";
.patient-detail {
  a {
    text-decoration: none;
  }
  &__button-card {
    margin-top: 20px;
    width: 80%;
    margin-left: 10%;
    padding: 50px 5px 50px 5px;
    &.card {
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: black;

      h4 {
        margin-top: 10px;
        color: black;
        text-decoration: none !important;
      }
    }
    &__count {
      font-size: 18px;
      position: absolute;
      top: 10px;
      right: 30px;
      background: green;
      padding: 5px 10px;
      border-radius: 100%;
      color: white;
    }
  }
}

.patient-info-card {
  padding: 10px 0px 10px 20px;
  &.card {
    box-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.2);
  }

  h5 {
    color: black;
  }
}
